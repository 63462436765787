.imi-sidebar {
  &__nav-item {
    a {
      white-space: normal;
    }
  }
  
  &__area-picker {
    background-color: rgba(0, 0, 21, 0.2);
  }
}

.c-sidebar-minimized {
  .imi-sidebar__area-picker {
    display:none;
  }
}