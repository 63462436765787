@import "../../../../node_modules/@coreui/coreui/scss/variables";

.imi-clear-filter-btn {
  /*border-color: $border-color;
  border-width: $border-width;
  border-radius: $border-radius;
  border-style: solid;
  margin-left: map_get($spacers, 2);*/
  
  > svg.c-icon {
    margin-top: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
    
    
  }
}