@import "../../../../node_modules/@coreui/coreui/scss/variables";
@import "../../../../node_modules/@coreui/coreui/scss/functions";

.imi-login-input {
  
  &__field {
    background-image: none !important;
  }
  
  &__spinner-container {
    width:45px;
    background-repeat: no-repeat;
    background-color: transparent !important;
    background-size:$input-height-inner-half;
    background-position: right $input-height-inner-quarter center;
    &--invalid {
      border: 1px solid map_get($theme-colors, "danger");
      background-image: escape-svg($form-feedback-icon-invalid);
    }
    &--valid {
      border: 1px solid map_get($theme-colors, "success");
      background-image: escape-svg($form-feedback-icon-valid);
    }
    
    &--checking {
      background-image: none !important;
    }
  }
  
}