.imi-planning {
  
  height:100%;
  position:relative;
  
  &__operations {
      
  }
  
  &__load-degree {
    width:6em !important;
  }
  
}