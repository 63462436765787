.imi-content-container {
  //padding-top:0;
  //overflow:hidden;
  //overflow-y:auto;
  &__planning {
    padding-top:0;
    &-container {
      padding-left: 0 !important;
      padding-right:0 !important;
      position: relative;
      height:100%;
    }
    
    &-fade {
      height:100%;
      position: relative;
    }
  }
}