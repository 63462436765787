@import "../../../../node_modules/@coreui/coreui/scss/variables";

// Override default react-datepicker
.react-datepicker {

  display: flex;
  
  &-popper {
    z-index:10;
  }
  
  &__time {
    flex-grow:1;
    position:relative;
    box-sizing: border-box;
    &-container {
      float:none;
      display: flex;
      flex-direction: column;
      
    }
    
    &-box  {
      position:relative;
    }
    
  }

  &__input-container {
    input {
      border: $border-width solid $border-color;
      border-radius: $border-radius;
      padding: $input-padding-y $input-padding-x;
      
      &.date-only {
        width: 7.5em;
      }
    }
  }
}

