@import "./variables";

.bg-brand {
  background-color: $brand-color;
}

.brand {
  color: $brand-color;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}